export const envConfig = {
  currentAPI: process.env.NODE_ENV === "development" ?
      'http://localhost:3011/'
      : 'https://api.labport.app/',
  currentCloudFunction: process.env.NODE_ENV === "development" ?
      'http://localhost:5001/hometestbox-aad36/us-central1/'
      : 'https://us-central1-hometestbox-aad36.cloudfunctions.net/',
  currentAppEnginePayment: process.env.NODE_ENV === "development" ?
      "http://localhost:1011/"
      : "https://engine.labport.app/",
  currentAppEngineVerify: process.env.NODE_ENV === "development" ?
      "http://localhost:1022/"
      : "https://engine.labport.app/",
  currentStripePublishableKey: process.env.NODE_ENV === "development" ?
      "pk_test_51KGqTwCr7V2TEN15cHAg6JWb7ex6GCw6buKCs3RPvjrzJvsFVZmy3pjHW49avSYinxM3mDwt14kErodbSztHijgk00xuBIkJ9o"
      : "pk_live_51KGqTwCr7V2TEN15yW7fltS8vGTYzfgEUD0jxOLYOtiDco36sXRtbCf5W9H5pmrUaQB3kAUdlMJY5TWUIYKS0NH400NtOa3YtW",
}
