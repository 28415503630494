import './App.css';
import {SnackbarProvider} from 'notistack';
import {SelectionProvider} from "./Contexts/PatientProvider";
import {useScript} from "./hooks/useScript";
import Router from './routes'

export default function App() {
  useScript(
      "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit",
      "head",
      false
  );
  useScript(
      "//translate.google.com/#en/hi/Hello",
      "head",
      false
  );
  useScript(
      "/GoogleTranslateInit.js",
      "head"
  );
  return (
      <SnackbarProvider maxSnack={3}>
        <SelectionProvider>
          <Router/>
        </SelectionProvider>
      </SnackbarProvider>
  );
}
