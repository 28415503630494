import {useEffect} from "react";
import lottie from 'lottie-web';
import {Grid, Typography} from "@mui/material";
import thankYouAnimation from "../Const/thankYouAnimation"

export default function ThankYou() {

  useEffect(() => {
    lottie.loadAnimation({
      container: document.getElementById("thankyouanimation"),
      renderer: "svg",
      loop: false,
      autoplay: true,
      animationData: thankYouAnimation,
    })
  }, []);

  return (<>
    <Grid container direction={"row"} alignItems={"center"}
          justifyContent={"center"}>
      <Grid xs={12} sm={4} md={2} lg={2} item id={"thankyouanimation"}/>
      <Grid item xs={12} lg={12}>
        <Typography color={"#F66D6D"} fontWeight={2}
                    sx={{textAlign: "center", fontSize: "5rem"}}>
          Thank you!
        </Typography>
      </Grid>
      <Grid item xs={12} lg={12} style={{textAlign: "center"}}>
        <Typography>
          You have successfully registered for testing.
          <br/>Remember, there is no appointment necessary.
          Please make sure to bring your photo ID on the testing day for faster
          check-in.
        </Typography>
        <Typography color={"#F66D6D"}> <br/>Questions? Please contact us at <a
            href={"mailto:support@labport.app"}>support@labport.app</a>.</Typography>
      </Grid>
    </Grid>
  </>)
}
