import {motion} from "framer-motion";
import {Backdrop, Divider, TextField, Typography} from "@mui/material";
import {useEffect, useState} from "react";
import isEmail from 'validator/es/lib/isEmail';
import {useNavigate} from "react-router-dom";
import {usePatient} from "../Contexts/PatientProvider";
import {useForm} from "react-hook-form";
import {ControlledMuiPhoneNumber} from "../Utils/ControlledComponents";
import {LoadingButton} from "@mui/lab";

export default function EmailAndPhone() {
  const {
    patientState,
    setPatientState,
    testingFacility,
    setTestingFacility,
    facilityData,
  } = usePatient();
  const {
    register,
    control,
    handleSubmit,
    formState: {errors},
  } = useForm({
    defaultValues: patientState
  });
  const [loading, setLoading] = useState(false);
  const [showBackdrop, setShowBackdrop] = useState(false)

  let navigate = useNavigate();

  const submitPhoneAndEmail = (data) => {
    setLoading(true);
    setPatientState({
      ...patientState,
      ...data,
    });
    setTimeout(() => {
      if (facilityData?.settings?.enableVouched) {
        navigate(`/id?site=${testingFacility}`);
      } else {
        navigate(`/demographics?site=${testingFacility}`);
      }
      setLoading(false);
    }, 100);
  };

  useEffect(() => {
    const site = new URLSearchParams(window.location.search).get("site");
    if (site) {
      setTestingFacility(site);
    } else {
      setShowBackdrop(true);
    }
  }, [setTestingFacility]);

  useEffect(() => {
    if (testingFacility) {
      setShowBackdrop(false);
    } else {
      setShowBackdrop(true);
    }
  }, [testingFacility]);

  return (<>
    <Backdrop
        style={{zIndex: 4, backgroundColor: "rgba(0,0,0,0.8)"}}
        color={"black"}
        open={showBackdrop}>
      <Typography style={{color: "#fff", textAlign: "center", margin: "20px"}}>
        The system could not recognize where you came from. Please re-scan the
        QR code and start over.
      </Typography>
    </Backdrop>
      <form onSubmit={handleSubmit(submitPhoneAndEmail)}>
        <motion.div
            initial={{opacity: 0, y: "20%"}}
            animate={{opacity: [0, 1, 0], y: ["0%", "0%", "10%"]}}
            transition={{
              opacity: {
                duration: 3
              },
              y: {
                duration: 3
              },
              ease: "easeOut"
            }}
            className={"centerEverything"}
        >
          <Typography style={{fontSize: "2rem"}}>
            Welcome to LabPort
          </Typography>
        </motion.div>
        <motion.div
            //parent container for Title (register patient)
            initial={"hidden"}
            animate={"show"}
            variants={{
              hidden: {
                opacity: 0,
              },
              show: {
                opacity: 1,
                transition: {
                  delay: 2.8,
                  delayChildren: 2.8,
                  staggerChildren: .5,
                }
              }
            }} className={"centerEverything"}
        >
          <motion.div
              variants={{
                hidden: {opacity: 0},
                show: {opacity: 1},
              }}>
            <Typography variant={"h2"} style={{fontSize: "2rem"}}>
              Register as a new patient
            </Typography>
          </motion.div>
          <motion.div
              variants={{
                hidden: {scaleX: "0%"},
                show: {
                  scaleX: "100%",
                  transition: {
                    duration: 2,
                  }
                },
              }}
          >
            <Divider style={{marginBottom: "1rem", marginTop: "1rem"}}/>
          </motion.div>
          <motion.div
              style={{marginBottom: "1rem", marginTop: "1rem"}}
              variants={{
                hidden: {opacity: 0},
                show: {opacity: 1}
              }}>
            <TextField
                inputProps={{
                  ...register("email", {
                    validate: (value) => isEmail(value),
                  })
                }}
                label={"Email"}
                autoComplete={"email"}
                required
                type={"email"}
                variant={"outlined"}
                error={Boolean(errors.email)}
                helperText={Boolean(errors.email) && "Invalid Email"}
                fullWidth/>
          </motion.div>
          <motion.div
              variants={{
                hidden: {opacity: 0},
                show: {opacity: 1}
              }}>
            <ControlledMuiPhoneNumber
                name={"phone"}
                control={control}
                label={"Phone"}
                autoComplete={"tel"}
                required
                type={"tel"}
                variant={"outlined"}
                defaultCountry={'us'}
                disableAreaCodes
                countryCodeEditable
                fullWidth/>
          </motion.div>
          <motion.div
              variants={{
                hidden: {opacity: 0, y: 0},
                show: {opacity: 1, y: "1rem"}
              }}>
            <LoadingButton
                type={"submit"}
                loading={loading}
                fullWidth
                color={"primary"}
                variant={"contained"}
                className={"fixButton"}>
              <span>Next</span>
            </LoadingButton>
          </motion.div>
        </motion.div>
      </form>
    </>
  );
}
