import {BrowserRouter, Link, Route, Routes} from 'react-router-dom';
import EmailAndPhone from "./Components/EmailAndPhone";
import VouchedReg from "./Components/VouchedReg";
import AdditionalInfo from "./Components/AdditionalInfo";
import Cart from "./Components/Cart";
import ThankYou from "./Components/ThankYou";

export default function Router() {
  return (
      <BrowserRouter>
        <Link to={"/demographics"}>
          <img
              src={"logo.svg"}
              alt="Logo"
              style={{
                width: "200px"
              }}/>
        </Link>
        <Routes>
          <Route path="/" element={<EmailAndPhone/>}/>
          <Route path="/id" element={<VouchedReg/>}/>
          <Route path="/demographics" element={<AdditionalInfo/>}/>
          <Route path="/cart" element={<Cart/>}/>
          <Route path="/thankyou" element={<ThankYou/>}/>
        </Routes>
      </BrowserRouter>
  );
}
