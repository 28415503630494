import {motion} from "framer-motion";
import {useCallback, useEffect, useRef, useState} from "react";
import CheckIcon from '@mui/icons-material/Check';
import {
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  Typography
} from '@mui/material';
import {loadStripe} from '@stripe/stripe-js';
import ReactCanvasConfetti from "react-canvas-confetti";
import {usePatient} from "../Contexts/PatientProvider";
import {useSnackbar} from "notistack";
import {Elements} from '@stripe/react-stripe-js';
import axios from "axios";
import CheckoutForm from "./Checkout";
import lottie from 'lottie-web';
import loadingCartAnimation from "../Const/loadingCartAnimation.json";
import {envConfig} from "../envConfig";

const stripePromise = loadStripe(envConfig.currentStripePublishableKey);

export default function Cart() {
  const {
    setTestingFacility,
    facilityData,
  } = usePatient();
  const {enqueueSnackbar} = useSnackbar();
  const [cartLoading, setCartLoading] = useState(false);
  const [clientSecret, setClientSecret] = useState(null);
  const [totalAmount, setTotalAmount] = useState(0);
  const [cartContents, setCartContents] = useState(
      {antigen: false, pcr: false, antigenAndPcr: false});
  const cartAnchor = useRef(null);
  const refAnimationInstance = useRef(null);

  //get the client secret/ payment intent
  useEffect(() => {
    const site = new URLSearchParams(window.location.search).get("site");
    const pid = new URLSearchParams(window.location.search).get("pid");
    if(site && pid) {
      setTestingFacility(site);
    }
    if (!Object.values(cartContents).includes(true)) {
      return;
    }
    if (site && pid) {
      // console.log(site,pid);
      setTestingFacility(site);
      try {
        setCartLoading(true)
        axios.post(envConfig.currentAppEnginePayment+"paymentIntent", {
          cart: cartContents,
          facilityId: site,
          url: window.location.href,
          patientId: pid,
        }).then(async (response) => {
          setClientSecret(response.data.clientSecret);
          setTotalAmount(response.data.totalAmount);
          setCartLoading(false);
          if (cartContents.antigenAndPcr) {
            fire();
          }
          setTimeout(() => {
            cartAnchor.current?.scrollIntoView(
                {behavior: 'smooth', block: 'start'})
          }, 600)
          // console.log("data",response.data);
        }).catch((e) => {
          enqueueSnackbar(e.response?.data?.status
              ?? "Unknown error. Please report this to our staff!", {
            variant: "error",
          });
          setCartLoading(false);
        });
      } catch (e) {
        setCartLoading(false);
        console.log(e);
      }
    } else {
      enqueueSnackbar(
          "Could not find the necessary parameters to issue your request.");
    }
  }, [cartContents, enqueueSnackbar, setTestingFacility]);

  const getInstance = useCallback((instance) => {
    refAnimationInstance.current = instance;
  }, []);

  const makeShot = useCallback((particleRatio, opts) => {
    refAnimationInstance.current &&
    refAnimationInstance.current({
      ...opts,
      origin: {y: 0.7},
      colors: ["#025fe0", "#e01a26", "#fff"],
      particleCount: Math.floor(200 * particleRatio)
    });
  }, []);

  const fire = useCallback(() => {
    makeShot(0.25, {
      spread: 26,
      startVelocity: 55
    });
    makeShot(0.2, {
      spread: 60
    });
    makeShot(0.35, {
      spread: 100,
      decay: 0.91,
      scalar: 0.8
    });
    makeShot(0.1, {
      spread: 120,
      startVelocity: 25,
      decay: 0.92,
      scalar: 1.2
    });
    makeShot(0.1, {
      spread: 120,
      startVelocity: 45
    });
  }, [makeShot]);

  useEffect(() => {
    if (cartContents.antigenAndPcr &&
        (cartContents.pcr || cartContents.antigen)) {
      setCartContents({
        pcr: false,
        antigen: false,
        antigenAndPcr: true,
      });
    }
  }, [cartContents])

  const handleCheckbox = (key, value) => {
    if(!value) {
      setClientSecret(null);
    }
    setCartContents({
      ...cartContents,
      [key]: value
    })
  }
  const appearance = {
    theme: 'stripe',
  };
  const options = {
    clientSecret,
    appearance,
  };

  return (
      <div
          style={{
            display: "grid",
            placeContent: "center"
          }}
      >
        <div>
          <motion.div
              initial={{opacity: 0, y: "20%"}}
              animate={{opacity: [0, 1, 0], y: ["0%", "0%", "10%"]}}
              transition={{
                opacity: {
                  duration: 3
                },
                y: {
                  duration: 3
                }
                , ease: "easeOut"
              }}
          >
            <Typography style={{fontSize: "2rem"}}>
              Select your test
            </Typography>
          </motion.div>
          <motion.div
              initial="hidden"
              animate={"show"}
              variants={{
                hidden: {
                  opacity: 0,
                },
                show: {
                  opacity: 1,
                  transition: {
                    delay: 2.8,
                    delayChildren: 2.8,
                    staggerChildren: .5,
                  }
                }
              }}
          >
            <motion.div
                variants={{
                  hidden: {opacity: 0},
                  show: {opacity: 1},
                }}>
              <Typography variant={"h2"} style={{fontSize: "2rem"}}>
                {(facilityData?.settings?.antigen || facilityData?.settings?.pcr)
                    ? "Which test would you like to purchase?"
                    : "This facility does not support test purchasing"}
              </Typography>
              <div className={"testsContainer"}>
                {facilityData?.settings?.antigen &&
                    <div
                     className={"testSelection"}
                     style={{margin: "5px"}}
                     onClick={(e) => {
                       if(cartContents.antigenAndPcr)
                         return;
                       handleCheckbox("antigen",
                           !cartContents.antigen)
                     }}>
                      <Typography variant={"h3"} style={{fontSize: "2rem"}}
                                  align={"left"}>
                        Rapid Antigen Test
                      </Typography>
                      <img src={"/test1.png"} style={{maxHeight: "200px"}}/>
                      <Typography align={"left"} className={"testFeatures"}>
                        Nasal swab sample, FDA Authorized on-site
                        test. Results in 30-45 minutes via text message and
                        email.<br/>
                        Perfect for:
                      </Typography>
                      {["School",
                        "Work",
                        "Events",
                        "Serial testing"].map((item, i) => {
                        return (
                            <Grid style={{display: "flex"}} key={i}
                                  className={"testFeatures"}>
                              <CheckIcon sx={{
                                color: 'primary.main'
                              }}/>
                              <Typography align={"left"}>{item}</Typography>
                            </Grid>
                        )
                      })}
                      <FormControlLabel
                          control={<Checkbox/>}
                          label={"$" + facilityData?.settings?.antigenPrice}
                          style={{ pointerEvents: "none" }}
                          disabled={cartContents.antigenAndPcr}
                          checked={cartContents.antigen}
                      />
                    </div>}
                {facilityData?.settings?.pcr &&
                  <div
                    className={"testSelection"}
                    style={{margin: "5px"}}
                    align={"left"}
                    onClick={(event) => {
                      if(cartContents.antigenAndPcr)
                        return;
                      handleCheckbox("pcr",
                          !cartContents.pcr)
                    }}>
                    <Typography
                        variant={"h3"}
                        style={{fontSize: "2rem"}}
                        align={"left"}>
                      PCR Test
                    </Typography>
                    <img src={"/test2.png"} style={{maxHeight: "200px"}}/>
                    <Typography align={"left"} className={"testFeatures"}>
                      Nasal swab sample, test performed in CLIA High Complexity
                      laboratory. Results in 24-36 hours via text message and
                      email.<br/>
                      Perfect for:
                    </Typography>
                    {["Travel", "Confirming an antigen test",
                      "Detecting the virus early",
                      "Testing asymptomatic patients"].map((item, i) => {
                      return (
                          <Grid style={{display: "flex"}} key={i}
                                className={"testFeatures"}>
                            <CheckIcon sx={{
                              color: 'primary.main'
                            }}/>
                            <Typography align={"left"}>{item}</Typography>
                          </Grid>
                      )
                    })}
                    <FormControlLabel
                        control={<Checkbox/>}
                        style={{ pointerEvents: "none" }}
                        disabled={cartContents.antigenAndPcr}
                        checked={cartContents.pcr}
                        label={"$" + facilityData?.settings?.pcrPrice}/>
                  </div>
                }
                {facilityData?.settings?.pcr && facilityData?.settings?.antigen &&
                    <div className={"testSelection"}
                         style={{margin: "5px"}}
                         align={"left"}
                         onClick={(e) => {
                           handleCheckbox(
                               "antigenAndPcr",
                               !cartContents.antigenAndPcr)
                         }}>
                      <ReactCanvasConfetti
                          refConfetti={getInstance}
                          style={{
                            position: "fixed",
                            pointerEvents: "none",
                            width: "100%",
                            height: "100%",
                            top: 0,
                            left: 0
                          }}/>
                      <Typography variant={"h3"} style={{fontSize: "2rem"}}
                                  align={"left"}>
                        Both Tests
                      </Typography>
                      <img src={"/test3.png"} style={{maxHeight: "200px"}}/>
                      <Grid style={{display: "flex"}}
                            className={"testFeatures"}>
                        <CheckIcon sx={{
                          color: 'primary.main'
                        }}/>
                        <Typography align={"left"}>Purchase both tests now to
                          save
                          time and get a discount!</Typography>
                      </Grid>
                      <FormControlLabel
                          control={<Checkbox/>}
                          style={{ pointerEvents: "none" }}
                          checked={cartContents.antigenAndPcr}
                          label={"$" + facilityData?.settings?.antigenAndPcr}
                      />
                    </div>}
              </div>
            </motion.div>
            <motion.div
                variants={{
                  hidden: {opacity: "0%"},
                  show: {
                    opacity: "100%",
                    transition: {
                      duration: 2,
                    }
                  },
                }}
            >
              <Divider style={{marginBottom: "1rem", marginTop: "1rem"}}/>
              {cartLoading ? <CartLoading/> : (clientSecret && Object.values(
                  cartContents).includes(true) && (
                  <Elements options={options} stripe={stripePromise}>
                    <CheckoutForm cart={cartContents}/>
                    <div style={{
                      display: "flex",
                      width: "inherit",
                      flexDirection: "row",
                      padding: "5px",
                      justifyContent: "start"
                    }}>
                      <Typography style={{fontSize: "2rem"}}>
                        Total: ${totalAmount}
                      </Typography>
                    </div>
                  </Elements>
              ))}
              <div ref={cartAnchor}/>
            </motion.div>
            <motion.div
                style={{marginBottom: "1rem", marginTop: "1rem"}}
                variants={{
                  hidden: {opacity: 0},
                  show: {opacity: 1}
                }}>
            </motion.div>
            <motion.div
                variants={{
                  hidden: {opacity: 0},
                  show: {opacity: 1}
                }}>
            </motion.div>
            <motion.div
                variants={{
                  hidden: {opacity: 0, y: 0},
                  show: {opacity: 1, y: "1rem"}
                }}>
            </motion.div>
          </motion.div>
        </div>
      </div>
  )
}

function CartLoading() {

  useEffect(() => {
    lottie.loadAnimation({
      container: document.getElementById("loadingCart"),
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: loadingCartAnimation,
    })
  }, []);

  return (
      <Grid container alignItems={"center"} justifyContent={"center"}>
        <Grid id="loadingCart" item lg={4} xs={8}/>
      </Grid>
  )
}
