import {useState} from "react";
import {PaymentElement, useElements, useStripe} from "@stripe/react-stripe-js";
import axios from "axios";
import Button from "@mui/material/Button";
import {useSnackbar} from "notistack";
import {Typography} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {envConfig} from "../envConfig";

const Checkout = () => {

    const elements = useElements();
    const stripe = useStripe();

    let navigate = useNavigate();

    const [errors, setErrors] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const {enqueueSnackbar} = useSnackbar();



    const handleSubmit = async (e) => {

        e.preventDefault();
        if (!stripe || !elements)
        {
            return
        }


        setIsLoading(true);
        const {error, paymentIntent} = await stripe.confirmPayment({
            elements,
            redirect : "if_required",
            confirmParams : {
                return_url : "https://labport.app/thankyou",
            }
        })

        if(error){
            setErrors(error)
            // console.log(error)
        }else{
            //go to thank you page and add/verify credit
            axios.post(envConfig.currentAppEnginePayment+"updateCredit",{
                paymentId : paymentIntent.id
            }).then((response)=>{
                // console.log(response.data)
                enqueueSnackbar("Credit added to your account!",{
                    variant : "success"
                })
                navigate("/thankyou");
            }).catch((e)=>{
                enqueueSnackbar("Credit could not be added to your account. Please get in touch with a LabPort agent.",{
                    variant : "error"
                })
                // navigate("/thankyou");
            })

        }
        // console.log(paymentIntent)

        // clientSecret, {
        //     payment_method: {
        //         card: elements.getElement(CardElement)
        //     }
        // }


        setIsLoading(false)
        // console.log(paymentIntent)

        // console.log(JSON.stringify(errors))

    }


    return (
        <>

            <form id="payment-form" onSubmit={handleSubmit}>
                <PaymentElement id="payment-element"/>
                <Button disabled={isLoading || !stripe || !elements} id="submit" variant={"contained"} color={"primary"}
                        sx={{marginTop:"10px"}}
                type={"submit"}
                >Pay
                </Button>
                {/* Show any error or success messages */}
               <div>
                   <Typography color={"red"} sx={{marginTop:"20px"}}>
                       {errors && errors.message}
                   </Typography>
               </div>
            </form>
        </>
    )
};

export default Checkout;
