import {createContext, useContext, useEffect, useState} from "react";
import axios from "axios";
import {envConfig} from "../envConfig";
import {useGoogleReCaptcha} from "react-google-recaptcha-v3";

const SelectionContext = createContext({});

export function usePatient() {
  return useContext(SelectionContext)
}

export function SelectionProvider({children}) {
  const {executeRecaptcha} = useGoogleReCaptcha();
  const [patientState, setPatientState] = useState({});
  const [providedId, setProvidedId] = useState(false);
  const [testingFacility, setTestingFacility] = useState(false);
  const [facilityData, setFacilityData] = useState(undefined);

  const value = {
    patientState,
    setPatientState,
    providedId,
    setProvidedId,
    testingFacility,
    setTestingFacility,
    facilityData,
  };

  useEffect(() => {
    if(facilityData) {
      return;
    }
    if (!executeRecaptcha) {
      return;
    }
    // console.log("testingFacility",typeof testingFacility, testingFacility)
    if (!testingFacility) {
      return;
    }
    (async () => {
      const token = await executeRecaptcha('getFacilityType');
      axios.post(
          envConfig.currentAppEngineVerify + "getFacility",
          {
            facilityId: testingFacility,
          },
          {headers: {recaptcha: token}}
      ).then((facility) => {
        setFacilityData(facility?.data);
      }).catch(() => {
        setTestingFacility(undefined);
      });
    })();
  }, [executeRecaptcha, facilityData, setFacilityData, setTestingFacility, testingFacility]);

  return (
      <SelectionContext.Provider value={value}>
        {children}
      </SelectionContext.Provider>
  );
}
