import {useEffect, useRef, useState} from "react";
import {
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  LinearProgress,
  Popover,
  TextField,
  Typography
} from "@mui/material";
import {createTheme, ThemeProvider} from '@mui/material/styles';
import EnhancedEncryptionIcon from '@mui/icons-material/EnhancedEncryption';
import axios from 'axios';
import {useSnackbar} from 'notistack';
import {useNavigate} from "react-router-dom";
import isEmail from 'validator/es/lib/isEmail';
import isMobilePhone from 'validator/es/lib/isMobilePhone';
import {useForm} from "react-hook-form";
import {LoadingButton} from "@mui/lab";
import {sexes} from "../Const/sexes";
import {ethnicities} from "../Const/ethnicities";
import {races} from "../Const/races";
import {states} from "../Const/states";
import {usePatient} from "../Contexts/PatientProvider";
import {identificationTypes} from "../Const/identificationTypes";
import {countries} from "../Const/countries";
import {usaStates} from "../Const/usaStates";
import {vaccines} from "../Const/vaccines";
import {envConfig} from "../envConfig";
import {
  ControlledAutocomplete,
  ControlledDesktopDatePicker,
  ControlledMuiPhoneNumber,
} from "../Utils/ControlledComponents";
import {defaultPatientTypes} from "../Const/defaultPatientTypes";
import {CustomSignaturePad} from "./SignaturePad";
import {ImageUploader} from "./ImageUploader";

export default function AdditionalInfo() {
  const signPad = useRef(null);
  const navigate = useNavigate();
  const {enqueueSnackbar} = useSnackbar();
  const {
    patientState,
    setPatientState,
    testingFacility,
    setTestingFacility,
    facilityData,
  } = usePatient();
  const {
    register,
    watch,
    control,
    handleSubmit,
    formState: {errors},
  } = useForm({
    defaultValues: patientState
  });
  const [loading, setLoading] = useState(false);
  const watchingAddressCountry = watch("address.country");
  const watchingHaveInsurance = watch("haveInsurance");
  const watchingHasVaccineCard = watch("hasVaccineCard");
  const watchingVaccineType = watch("vaccine.type");

  const [ssnAnchor, setSsnAnchor] = useState(null);
  const ssnOpen = Boolean(ssnAnchor);

  const [idFile, setIdFile] = useState();
  const [insuranceFile, setInsuranceFile] = useState();
  const [vaccineFile, setVaccineFile] = useState();

  const onError = () => {
    enqueueSnackbar(
        "Please correct invalid fields.",
        {variant: 'error',});
  }

  function validatePhone(phone) {
    if (!phone) {
      return false;
    }
    return isMobilePhone(phone);
  }

  useEffect(() => {
    const site = new URLSearchParams(window.location.search).get("site");
    if (site) {
      setTestingFacility(site);
    }
  }, [setTestingFacility]);

  const registerPatient = async (data) => {
    //error checking
    if (!validatePhone(data?.phone)) {
      enqueueSnackbar(
          "Could not verify phone number. "
          + "Please enter a valid phone number.",
          {variant: 'error',});
      return;
    }
    if (!testingFacility) {
      enqueueSnackbar("Please rescan the QR code!",
          {variant: 'error',});
      return;
    }
    if (signPad.current.isEmpty()) {
      enqueueSnackbar("Please sign the form!",
          {variant: 'error',});
      return;
    }
    if (facilityData?.settings?.id
        && facilityData?.settings?.enforceId
        && !idFile) {
      enqueueSnackbar("Please upload your photo ID to continue!",
          {variant: 'error',});
      return;
    }
    if (data?.haveInsurance === "Yes"
        && facilityData?.settings?.enforceInsurance
        && !insuranceFile) {
      enqueueSnackbar("Please upload your Insurance card to continue!",
          {variant: 'error',});
      return;
    }
    if (facilityData?.settings?.enforceVaccineCard && !vaccineFile) {
      enqueueSnackbar("Please upload your vaccine card to continue!",
          {variant: 'error',});
      return;
    }

    setLoading(true);

    const signatureImage = signPad.current.toDataURL("image/svg+xml");

    data.searchField = data?.fname?.toLowerCase();
    data.ssn = data?.ssn?.replaceAll(/[\s-]+/g, "");
    data.facilityId = testingFacility;
    data.signature = signatureImage;
    if (data?.haveInsurance === "Yes") {
      data.billTo = "Insurance";
    }
    if (data?.haveInsurance === "No") {
      data.billTo = "No Insurance";
    }

    setPatientState({
      ...patientState,
      ...data,
    });

    const formData = new FormData();

    //note: method only works with 1 layer object literals and string values
    // will break if keys or values are set to false
    // might use npm object-to-formdata in the future
    Object.entries(data).filter(([key, value]) => key && value)
    .forEach(([key, value]) => {
      if (typeof value !== 'string') {
        Object.entries(value).forEach(([subKey, subValue]) => {
          formData.append(key + '.' + subKey, subValue);
        });
      } else {
        formData.append(key, value);
      }
    });

    if (idFile) {
      const fileArrayBuffer = await idFile.file.arrayBuffer();
      const fileBlob = new Blob([fileArrayBuffer],
          {type: idFile.file.type});
      formData.append('idFile', fileBlob, idFile.file.name);
    }
    if (insuranceFile) {
      const fileArrayBuffer = await insuranceFile.file.arrayBuffer();
      const fileBlob = new Blob([fileArrayBuffer],
          {type: insuranceFile.file.type});
      formData.append('insuranceFile', fileBlob, insuranceFile.file.name);
    }
    if (vaccineFile) {
      const fileArrayBuffer = await vaccineFile.file.arrayBuffer();
      const fileBlob = new Blob([fileArrayBuffer],
          {type: vaccineFile.file.type});
      formData.append('vaccineFile', fileBlob, vaccineFile.file.name);
    }

    const config = {
      method: 'post',
      url: envConfig.currentCloudFunction + "register",
      data: formData,
      header: {
        'Accept': 'application/json',
        'Content-Type': 'multipart/form-data',
      },
    };

    axios(config).then(function (response) {
      if (response.status === 200) {
        enqueueSnackbar(response.data.message, {
          variant: 'success',
        });
        if (facilityData?.settings?.antigen || facilityData?.settings?.pcr) {
          navigate(`/cart?pid=${response.data.pid}&site=${testingFacility}`);
          setLoading(false);
          return;
        }
        navigate("/thankyou");
      }
      setLoading(false);
    }).catch((error) => {
      if (!error.response) {
        enqueueSnackbar("Network Error: Connection to the server was refused!",
            {
              variant: 'error',
            });
      } else {
        const errorMessage = error.response.data?.message ?? "Network Error";
        enqueueSnackbar(errorMessage, {
          variant: 'error',
        });
        if (errorMessage.includes("check")) {
          setTimeout(() => {
            window.location.href = `https://checkin.labport.app/?site=${testingFacility}`
          }, 3000);
        }
      }
      setLoading(false);
    });
  };

  const theme = createTheme();

  return (
      <ThemeProvider theme={theme}>
        {loading &&
            <LinearProgress
                color={"secondary"}
                style={{
                  position: "fixed",
                  top: "0px",
                  right: "0px",
                  left: "0px",
                  zIndex: "1",
                }}/>
        }
        <Popover
            open={ssnOpen}
            anchorEl={ssnAnchor}
            onClose={() => {
              setSsnAnchor(null);
            }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}>
          <Typography sx={{p: 2}}>
            We may need your social security number to verify who you are.
            Not providing a social security may run the risk of you getting
            a bill for your services.
          </Typography>
        </Popover>
        <form onSubmit={handleSubmit(registerPatient, onError)}>
          <Grid container direction={"row"} spacing={2} style={{
            padding: "10px 10px 10px 10px",
            maxWidth: "600px",
            margin: "0 auto"
          }}>
            <Grid item xs={12}>
              <Typography gutterBottom variant={"h4"}>
                Patient Registration
              </Typography>
            </Grid>
            {!testingFacility &&
                <Grid item xs={12}>
                  <TextField
                      label={"Testing Site Code"}
                      required
                      disabled={Boolean(testingFacility)}
                      onBlur={(event) => {
                        setTestingFacility(event.target.value)
                      }}
                      variant={"outlined"}
                      fullWidth/>
                </Grid>
            }
            {/*Patient Information*/}
            <Grid item xs={12}>
              <Typography>
                Patient Information
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Divider/>
            </Grid>
            <Grid item sm={6} xs={12}>
              <TextField
                  inputProps={{...register("fname")}}
                  label={"First Name"}
                  autoFocus
                  autoComplete={"given-name"}
                  required
                  variant={"outlined"}
                  fullWidth/>
            </Grid>
            <Grid item sm={6} xs={12}>
              <TextField
                  inputProps={{...register("lname")}}
                  label={"Last Name"}
                  autoComplete={"family-name"}
                  required
                  variant={"outlined"}
                  fullWidth/>
            </Grid>
            <Grid item sm={6} xs={12}>
              <ControlledMuiPhoneNumber
                  name={"phone"}
                  control={control}
                  label={"Phone"}
                  autoComplete={"tel"}
                  required
                  type={"tel"}
                  variant={"outlined"}
                  defaultCountry={'us'}
                  disableAreaCodes
                  fullWidth/>
            </Grid>
            <Grid item sm={6} xs={12}>
              <TextField
                  inputProps={{
                    ...register("email", {
                      validate: (value) => isEmail(value),
                    })
                  }}
                  label={"Email"}
                  autoComplete={"email"}
                  required
                  type={"email"}
                  variant={"outlined"}
                  error={Boolean(errors.email)}
                  helperText={Boolean(errors.email) && "Invalid Email"}
                  fullWidth/>
            </Grid>
            <Grid item sm={6} xs={12}>
              <ControlledDesktopDatePicker
                  name={"dob"}
                  control={control}
                  label={"Date of Birth"}
                  autoComplete={"bday"}
                  required
                  inputFormat={"MM/dd/yyyy"}
                  minimumDate={new Date("1900-01-01")}
                  disableFuture/>
            </Grid>
            <Grid item sm={6} xs={12}>
              <ControlledAutocomplete
                  name={"address.country"}
                  control={control}
                  label={"Country of Residence"}
                  options={countries}
                  autoComplete={"new-password"}
                  required
                  variant={"outlined"}/>
            </Grid>
            <Grid item sm={6} xs={12}>
              <TextField
                  inputProps={{...register("address.line1")}}
                  label={"Address"}
                  autoComplete={"street-address"}
                  required
                  variant={"outlined"}
                  fullWidth/>
            </Grid>
            <Grid item sm={6} xs={12}>
              <TextField
                  inputProps={{...register("address.city")}}
                  label={"City"}
                  autoComplete={"address-level2"}
                  required
                  variant={"outlined"}
                  fullWidth/>
            </Grid>
            {(watchingAddressCountry === "United States"
                    || watchingAddressCountry == null) &&
                <Grid item sm={6} xs={12}>
                  <ControlledAutocomplete
                      name={"address.state"}
                      control={control}
                      label={"State"}
                      options={usaStates}
                      autoComplete={"new-password"}
                      required
                      variant={"outlined"}/>
                </Grid>
            }
            {(watchingAddressCountry === "United States"
                    || watchingAddressCountry === "Canada"
                    || watchingAddressCountry == null) &&
                <Grid item sm={6} xs={12}>
                  <TextField
                      inputProps={{...register("address.zip")}}
                      label={"Postal Code"}
                      autoComplete={"postal-code"}
                      required
                      variant={"outlined"}
                      fullWidth/>
                </Grid>
            }
            {(facilityData?.settings?.ssn && watchingAddressCountry
                    === "United States") &&
                <Grid item sm={6} xs={12}>
                  <TextField
                      inputProps={{
                        ...register("ssn", {
                          pattern: /^[-\s0-9\b]+$/,
                        }),
                      }}
                      label={"Social Security Number or TIN"}
                      error={Boolean(errors.ssn)}
                      helperText={Boolean(errors.ssn)
                          && "Must only contain numbers."}
                      InputProps={{
                        endAdornment: !facilityData?.settings?.enforceSsn &&
                            <EnhancedEncryptionIcon
                                onClick={(event) => {
                                  setSsnAnchor(event.currentTarget);
                                }}/>
                      }}
                      autoComplete={"new-password"}
                      required={facilityData?.settings?.enforceSsn}
                      variant={"outlined"}
                      fullWidth/>
                </Grid>
            }
            {(facilityData?.settings?.insurance &&
                    watchingAddressCountry === "United States") &&
                <Grid item sm={6} xs={12}>
                  <ControlledAutocomplete
                      name={"haveInsurance"}
                      control={control}
                      label={"Do you have health insurance?"}
                      options={["Yes", "No"]}
                      autoComplete={"new-password"}
                      required={facilityData?.settings?.enforceInsurance}
                      variant={"outlined"}/>
                </Grid>
            }
            {!facilityData?.settings?.enforceVaccineCard
                && facilityData?.settings?.vaccineCard &&
                <Grid item sm={6} xs={12}>
                  <ControlledAutocomplete
                      name={"hasVaccineCard"}
                      control={control}
                      label={"Proof of vaccination"}
                      options={["Yes", "No"]}
                      autoComplete={"new-password"}
                      required={facilityData?.settings?.enforceVaccineCard}
                      variant={"outlined"}/>
                </Grid>
            }
            <Grid item sm={6} xs={12}>
              <ControlledAutocomplete
                  name={"sex"}
                  control={control}
                  label={"Sex"}
                  options={sexes}
                  autoComplete={"new-password"}
                  required
                  variant={"outlined"}/>
            </Grid>
            <Grid item sm={6} xs={12}>
              <ControlledAutocomplete
                  name={"ethnicity"}
                  control={control}
                  label={"Ethnicity"}
                  options={ethnicities}
                  autoComplete={"new-password"}
                  required
                  variant={"outlined"}/>
            </Grid>
            <Grid item sm={6} xs={12}>
              <ControlledAutocomplete
                  name={"race"}
                  control={control}
                  label={"Race"}
                  options={races}
                  autoComplete={"new-password"}
                  required
                  variant={"outlined"}/>
            </Grid>
            {facilityData?.settings?.collectPatientType &&
                <Grid item sm={6} xs={12}>
                  <ControlledAutocomplete
                      name={"patientType"}
                      control={control}
                      label={"Patient Category"}
                      options={facilityData.patientTypes?.length > 0 ?
                          facilityData.patientTypes : defaultPatientTypes}
                      autoComplete={"new-password"}
                      required={facilityData.settings.enforcePatientType}
                      variant={"outlined"}/>
                </Grid>
            }
            {/*Employee ID Information*/}
            {facilityData?.settings?.collectEmployeeInfo &&
                <>
                  <Grid item xs={12}>
                    <Divider/>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography>
                      Employee ID Information
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider/>
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <TextField
                        inputProps={{...register("employeeId")}}
                        label={"Employee ID"}
                        autoComplete={"new-password"}
                        required={facilityData?.settings?.enforceEmployeeInfo}
                        variant={"outlined"}
                        fullWidth/>
                  </Grid>
                  {facilityData?.departments?.length > 0 &&
                      <Grid item sm={6} xs={12}>
                        <ControlledAutocomplete
                            name={"employeeDept"}
                            control={control}
                            label={"Department"}
                            options={facilityData.departments}
                            autoComplete={"new-password"}
                            required={facilityData?.settings?.enforceEmployeeInfo}
                            variant={"outlined"}/>
                      </Grid>
                  }
                  {facilityData?.locations?.length > 0 &&
                      <Grid item sm={6} xs={12}>
                        <ControlledAutocomplete
                            name={"employeeLocation"}
                            control={control}
                            label={"Location"}
                            options={facilityData.locations}
                            autoComplete={"new-password"}
                            required={facilityData?.settings?.enforceEmployeeInfo}
                            variant={"outlined"}/>
                      </Grid>
                  }
                </>
            }
            {/*Government ID Information*/}
            {facilityData?.settings?.id &&
                <>
                  <Grid item xs={12}>
                    <Divider/>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography>
                      Government ID Information
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider/>
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <ControlledAutocomplete
                        name={"identification.type"}
                        control={control}
                        label={"Type of ID"}
                        options={Object.values(identificationTypes)}
                        autoComplete={"new-password"}
                        required={facilityData?.settings?.enforceId}
                        variant={"outlined"}/>
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <TextField
                        inputProps={{...register("identification.number")}}
                        label={"ID Number"}
                        autoComplete={"new-password"}
                        required={facilityData?.settings?.enforceId}
                        variant={"outlined"}
                        fullWidth/>
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <ControlledDesktopDatePicker
                        name={"identification.exp"}
                        control={control}
                        label={"Expiration Date"}
                        autoComplete={"new-password"}
                        required={facilityData?.settings?.enforceId}
                        minimumDate={new Date("1900-01-01")}
                        inputFormat={"MM/dd/yyyy"}/>
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <ControlledAutocomplete
                        name={"identification.state"}
                        control={control}
                        label={"Issuance Place"}
                        options={states}
                        autoComplete={"new-password"}
                        required={facilityData?.settings?.enforceId}
                        variant={"outlined"}/>
                  </Grid>
                  <Grid item xs={12}>
                    <ImageUploader
                        setFile={setIdFile}
                        inputString={'Upload your photo ID'}
                    />
                  </Grid>
                </>
            }
            {/*Insurance Information*/}
            {watchingHaveInsurance === "Yes" &&
                <>
                  <Grid item xs={12}>
                    <Divider/>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography>
                      Insurance Information
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider/>
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <TextField
                        inputProps={{...register("insurance.carrier")}}
                        label={"Insurance Provider"}
                        required={facilityData?.settings?.enforceInsurance}
                        variant={"outlined"}
                        fullWidth/>
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <TextField
                        inputProps={{...register("insurance.policyNumber")}}
                        label={"Policy Number/Member ID"}
                        required={facilityData?.settings?.enforceInsurance}
                        variant={"outlined"}
                        fullWidth/>
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <TextField
                        inputProps={{...register("insurance.groupNumber")}}
                        label={"Group Number"}
                        required={facilityData?.settings?.enforceInsurance}
                        variant={"outlined"}
                        fullWidth/>
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <TextField
                        inputProps={{...register("insurance.subscriberName")}}
                        label={"Subscriber Name"}
                        required={facilityData?.settings?.enforceInsurance}
                        variant={"outlined"}
                        fullWidth/>
                  </Grid>
                  <Grid item xs={12}>
                    <ImageUploader
                        setFile={setInsuranceFile}
                        inputString={'Upload a photo of your insurance card'}
                    />
                  </Grid>
                </>
            }
            {/*Vaccination Information*/}
            {(facilityData?.settings?.enforceVaccineCard
                    || watchingHasVaccineCard === "Yes") &&
                <>
                  <Grid item xs={12}>
                    <Divider/>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography>
                      Vaccination Information
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider/>
                  </Grid>
                  <Grid item sm={6} xs={12} translate="no">
                    <ControlledAutocomplete
                        name={"vaccine.type"}
                        control={control}
                        label={"Vaccine(s) Received"}
                        options={vaccines}
                        autoComplete={"new-password"}
                        required={facilityData?.settings?.enforceVaccineCard}
                        multiple
                        autoCompleteProps={{
                          limitTags: 1,
                        }}
                        variant={"outlined"}/>
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <ControlledDesktopDatePicker
                        name={"vaccine.firstDoseDate"}
                        control={control}
                        label={"Date of First Dose"}
                        required={facilityData?.settings?.enforceVaccineCard}
                        inputFormat={"MM/dd/yyyy"}
                        minimumDate={new Date("2020-01-01")}
                        disableFuture/>
                  </Grid>
                  {(watchingVaccineType?.some(v => v.includes("Moderna"))
                          || watchingVaccineType?.some(v => v.includes("Pfizer")))
                      &&
                      <Grid item sm={6} xs={12}>
                        <ControlledDesktopDatePicker
                            name={"vaccine.secondDoseDate"}
                            control={control}
                            label={"Date of Second Dose"}
                            required={facilityData?.settings?.enforceVaccineCard}
                            inputFormat={"MM/dd/yyyy"}
                            minimumDate={new Date("2020-01-01")}
                            disableFuture/>
                      </Grid>
                  }
                  <Grid item sm={6} xs={12}>
                    <ControlledDesktopDatePicker
                        name={"vaccine.boosterDate"}
                        control={control}
                        label={"Date of Booster"}
                        required={facilityData?.settings?.enforceVaccineCard}
                        inputFormat={"MM/dd/yyyy"}
                        minimumDate={new Date("2020-01-01")}
                        disableFuture/>
                  </Grid>
                  <Grid item xs={12}>
                    <ImageUploader
                        setFile={setVaccineFile}
                        inputString={'Upload your vaccination record'}
                    />
                  </Grid>
                </>
            }
            {/*Sign and Submit*/}
            <Grid item xs={12}>
              <FormControlLabel
                  value={"end"}
                  control={<Checkbox required color={"primary"}/>}
                  label={<>By signing below and submitting my registration I
                    have
                    read the <a href={facilityData?.consentLink}
                                rel={"noreferrer"}
                                target={"_blank"}> Informed Consent for COVID-19
                      Diagnostic Testing</a> and hereby provide my authorization
                    and consent to the terms therein. I authorize receipt of my
                    results via secure text message and/or secure email and
                    release of my results to the site sponsoring this
                    testing.</>}
                  labelPlacement={"end"}/>
            </Grid>
            <Grid item xs={12}>
              <CustomSignaturePad ref={signPad}/>
            </Grid>
            <Grid item xs={12}>
              <LoadingButton
                  loading={loading}
                  type={"submit"}
                  style={{width: "100%"}}
                  color={"primary"}
                  variant={"contained"}
                  className={"primaryColor, fixButton"}>
                <span>Submit</span>
              </LoadingButton>
            </Grid>
          </Grid>
        </form>
      </ThemeProvider>
  );
}
