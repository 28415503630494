import {useEffect, useState,} from 'react';
import {useSnackbar} from "notistack";
import {motion} from "framer-motion";
import {usePatient} from "../Contexts/PatientProvider";
import {useNavigate} from "react-router-dom";
import {identificationTypes} from "../Const/identificationTypes";
import {countriesShort} from "../Const/countriesShort";
import {Backdrop, Button, Typography} from "@mui/material";
import {statesByAbbreviations} from "../Const/states";

export default function VouchedReg() {
  const {enqueueSnackbar} = useSnackbar();
  const [showBackdrop, setShowBackdrop] = useState(false)
  const navigate = useNavigate();
  const {
    patientState,
    setPatientState,
    setProvidedId,
    testingFacility,
    setTestingFacility,
    facilityData,
  } = usePatient();

  const vouchedOnDone = (job) => {
    const data = {identification: {}, address: {}};
    if (job.result?.firstName) {
      data.fname = job.result.firstName[0] +
          job.result.firstName.substr(1).toLowerCase();
    }
    if (job.result?.lastName) {
      data.lname = job.result.lastName[0] +
          job.result.lastName.substr(1).toLowerCase();
    }
    data.gender =
        job.result?.gender?.gender === "man" ? "Male" : "Female";
    data.dob = job.result?.birthDate ?? "";
    data.identification.type = identificationTypes[job.result.type] ?? "Other";
    data.identification.number = (job.result?.id ?? "");
    data.identification.state = statesByAbbreviations[job.result?.state] ?? "";
    data.identification.exp = job.result?.expireDate ?? "";
    if (job.result?.idAddress?.streetNumber && job.result?.idAddress?.street) {
      data.address.line1 = job.result.idAddress.streetNumber + " "
          + job.result.idAddress.street;
    }
    data.address.city = job.result?.idAddress?.city;
    data.address.state = statesByAbbreviations[job.result?.idAddress?.state]
        ?? "";
    data.address.zip = job.result?.idAddress?.postalCode;
    data.address.country =
        countriesShort[job?.result?.idAddress?.country.toUpperCase()] ?? "";
    if (job.errors?.length > 0) {
      enqueueSnackbar(
          "Could not auto complete all the fields. "
          + "Please fill the rest of the fields manually",
          {
            variant: 'error',
          });
    } else {
      setProvidedId(true);
    }
    setPatientState({
      ...patientState,
      ...data,
    });
    navigate(`/demographics?site=${testingFacility}`);
  };

  useEffect(() => {
    const site = new URLSearchParams(window.location.search).get("site");
    if (site) {
      setTestingFacility(site);
      const vouchScriptTag = document.createElement('script');
      vouchScriptTag.src = "https://static.vouched.id/widget/vouched-2.0.0.js";
      vouchScriptTag.addEventListener('load', () => {
        // eslint-disable-next-line no-undef
        const vouched = Vouched({
          "appId": "8.ODBz!tQ-#7SyscR_vV6nT3Nvs4xC",
          "showProgressBar": true,
          "handoffView": {
            "onlyShowQRCode": false,
          },
          "token": "",
          "type": "id",
          "showTermsAndPrivacy": false,
          "face": null,
          "liveness": null,
          "onDone": vouchedOnDone,
          "survey": false,
          "theme": {
            "name": "avant",
            "baseColor": "#635BFF",
            "logo": {},
            "font": "Arial, Helvetica, sans-serif",
          }
        });
        vouched.mount("#vouched-element");
      });
      document.head.appendChild(vouchScriptTag);
    } else {
      setShowBackdrop(true);
    }
  }, [setTestingFacility]);

  useEffect(() => {
    if (testingFacility) {
      setShowBackdrop(false);
    } else {
      setShowBackdrop(true);
    }
  }, [testingFacility]);

  useEffect(() => {
    if(!facilityData) {
      return;
    }
    if (!facilityData.settings?.enableVouched) {
      navigate(`/demographics?site=${testingFacility}`);
    }
  }, [facilityData, navigate, testingFacility]);

  return (<>
    <Backdrop
        style={{zIndex: 4, backgroundColor: "rgba(0,0,0,0.8)"}}
        color={"black"}
        open={showBackdrop}>
      <Typography style={{color: "#fff", textAlign: "center", margin: "20px"}}>
        The system could not recognize where you came from. Please re-scan the
        QR code and start over.
      </Typography>
    </Backdrop>
    <Typography style={{fontSize: "2rem"}}>
      Please Scan Your ID.
    </Typography>
    <Button
        style={{
          display: "block",
          margin: "0 auto",
          minWidth: "40%",
          marginBottom: "1rem",
          backgroundColor: "#d2d2d2",
          color: "#a8a8a8 !important",
        }}
        onClick={() => {
          if (!testingFacility) {
            enqueueSnackbar(
                "The system could not recognize where you came from. "
                + "Please re-scan the QR code and start over.",
                {variant: "error"});
            return;
          }
          navigate(`/demographics?site=${testingFacility}`);
        }}
    >
      Enter Manually
    </Button>
    <motion.div>
      <div
          id="vouched-element"
          style={{
            maxWidth: "1000px",
            margin: "0 auto",
            height: "70vh",
          }}/>
    </motion.div>
  </>)

}
