import 'react-dropzone-uploader/dist/styles.css';
import Dropzone from 'react-dropzone-uploader';
import {Stack, Typography} from "@mui/material";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

export const ImageUploader = (props) => {
  const {setFile, inputString} = props;

  // called every time a file's `status` changes
  const handleChangeStatus = (file, status) => {
    if (status === "done") {
      setFile(file);
    }
    if (status === "removed") {
      setFile(undefined);
    }
  };

  const InputContent = () => {
    return (
        <Stack
            key={Math.random()}
            direction="column"
            justifyContent="center"
            alignItems="center"
        >
          <Typography variant={"h5"}>
            {inputString}
          </Typography>
          <CloudUploadIcon
              style={{fontSize: 51}}
          />
        </Stack>
    );
  }

  return (
      <Dropzone
          onChangeStatus={handleChangeStatus}
          inputContent={InputContent}
          accept="image/*"
          maxFiles={1}
          maxSizeBytes={10485760}
          multiple={false}
      />
  )
};
